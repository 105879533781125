import { showToast } from '@/js/helpers/toastHelpers';
import { getTrans } from '@/js/helpers/getTrans';

const copyToClipboard = ({ text, successMessage, errorMessage }: {text: string, successMessage: string, errorMessage: string}) => {
    const clipboard = navigator.clipboard;

    if (clipboard) {
        clipboard.writeText(text)
            .then(() => {
                showToast({
                    message: successMessage,
                    type: 'success',
                });
            })
            .catch(() => {
                showToast({
                    message: errorMessage,
                });
            });
    } else {
        showToast({
            message: errorMessage,
        });
    }
};

export const copyShareText = (shareText: string, shareUrl?: string): void => {
    if (!shareText) {
        showToast({
            message: getTrans('quests-dashboard.shareTextNotAvailableToast', 'Share text is not available.'),
        });

        return;
    }

    let textToCopy = shareText;

    if (shareUrl) {
        textToCopy += `\n${shareUrl}`;
    }

    copyToClipboard({
        text: textToCopy,
        successMessage: getTrans('quests-dashboard.shareTextCopiedSuccessfullyToast', 'Share text copied successfully!'),
        errorMessage: getTrans('quests-dashboard.shareUnableToCopyToast', 'Unable to copy share text.'),
    });
};

export const copyShareLink = (link: string) => {
    if (!link) {
        showToast({
            message: 'Share link is not available.',
        });

        return;
    }

    copyToClipboard({
        text: link,
        successMessage: 'Share link copied successfully!',
        errorMessage: 'Unable to copy share link.',
    });
};
